.FOOTER {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: auto; */
  /* min-height: 25px; */
  /* border-top: 1px solid lightgray; */
  /* min-height: 25px; */
  /* background: lightsalmon; */
  /* background: rgba(255,255,255,.75) */
}
.FOOTER * {
  /* border: 1px dashed rgb(240, 190, 97); */
}

.footer-holder {
  position: relative;
  height: 60px;
}

.footer__spacer {
  width: 120px;
}

.footer__icons {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}
.footer-icons__item {
  height: 20px;
  width: 40px;
  padding: 0 5px;
  opacity: 0.5;
  transition: all .2s ease-in 0s;
}
.footer-icons__item:hover {
  opacity: 0.8;
}

.footer-icons__item svg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}



@media (max-width: 500px) {
  .FOOTER.menu-open {
    display: none;
  }
  .footer__icons {
    top: 8px;
    left: 0;
    width: 100%;
    justify-content: center;
  }
  .footer-icons__item {
    /* height: 18px; */
  }
}
