/* .About * {
  border: 1px dashed rgba(184, 163, 124, 0.2);
} */
/* .About {
} */

.About > section {
  margin: 50px 0;
}

.about__brand,
.about__personal {
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.about__section__image {
  /* height: 100%; */
  width: 30%;
  max-width: 200px;
}
.about__section__image > img {
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  min-height: 20px;
}
.about__section__body {
  width: 65%;
  max-width: 400px;
  padding-left: 20px;
}

.about-section__disc p {
  font-size: 1rem;
  text-align: justify;
}

.about-section__title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.about-section__icons {
  display: flex;
  justify-content: space-evenly;
  /* justify-content: space-between; */
  justify-content: center;
  margin: 10px 0;}

.about-section__icons img {
  /* width: 100px; */
  /* width: 20%; */
  height: 100%;
  min-height: 20px;
  margin: 0 10px;
  object-fit: contain;
  box-shadow: 0px 0px 10px rgba(150, 150, 150, 0.1);
}

.about__personal .about-section__icons {
  height: 120px;
}
.about__personal .about-section__icons img {
  box-shadow: none;
}

.about__awards .about-section__icons {
  height: 130px;
}
.about__awards .about-section__icons img {
  width: 16%;
}

/* .about__publication .about-section__icons {
} */
.about__publication .about-section__icons img {
  width: 22.2%;
}

/* .about__brand .about-section__disc {
  margin-top: 50px;
} */

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .about__brand,
  .about__personal {
    flex-direction: column;
  }

  .about__brand .about-section__disc {
    margin-top: 5px;
  }

  .about-section__disc p {
    font-size: 0.95rem;
    word-spacing: 0px;
    line-height: 17px;
  }
  .about__section__image {
    width: 180px;
    padding-left: 20px;
    margin-bottom: 5px;
  }
  .about__section__image > img {
    height: 100%;
    object-fit: contain;
  }
  .about__section__body {
    width: 100%;
    max-width: 500px;
    padding-right: 20px;
  }
  .about__personal .about-section__icons {
    height: 100px;
  }
  .about__awards .about-section__icons {
    height: 100px;
  }
  .about__awards .about-section__icons img {
    width: 20%;
  }
}

@media (min-width: 550px) and (max-width: 750px) {
  .about-section__disc p {
    font-size: 1rem;
    word-spacing: 0px;
    line-height: 18px;
  }
  .about-section__icons {
    height: 200px;
  }
  .about__personal .about-section__icons {
    height: 120px;
  }
  .about__awards .about-section__icons {
    height: 110px;
  }
}

@media (min-width: 750px) {
}
