.Home * {
  transition: all 0.5s ease-in-out 0s;
}

/* .Home { */
/* width: 100vw; */
/* height: calc(100vh - 138px); */
/* } */

.home__background-holder {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}
.home__background-holder {
  /* height: calc(100vh - 130px); */
  height: 100%;
  /* top: 70px; */
  top: 0px;
}

.home-background__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.home-background__item.active {
  opacity: 1;
}
.home-background__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home__info {
  position: fixed;
  bottom: 100px;
  width: 100vw;
}

.home__info-holder {
  /* min-width: 300px; */
  /* width: 50%; */
  /* max-width: 300px; */
  /* background-color: rgba(255, 255, 255, 0.6); */
  text-align: center;
}


.home-info__control {
  display: flex;
  display: inline-flex;/* STYLE UPDATE:------- if wanted to be center ----- */
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  /* width: 300px; */
  height: 20px;
  padding: 5px 2px;
  padding: 5px ; /* STYLE UPDATE:-------  */
  /* border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px; */
  border-radius: 5px 5px 0 0; 
  border-radius: 10px ; /* STYLE UPDATE:-------  */
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  /* background-color: rgba(255, 255, 255, 0.5); */
}
.home-info-control__item {
  height: 11px;
  width: 11px;
  margin: 0 10px;
  border: 1px solid var(--theme_color-1);
  border-radius: 50%;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.4);/* STYLE UPDATE:-------  */
}
.home-info-control__item.active {
  border: 1px solid white;
  border: 1px solid rgba(255, 255, 255, 0.4);/* STYLE UPDATE:-------  */
  background-color: var(--theme_color-1);
}

.home-info__desc {
  position: relative;
  width: 300px;
  height: 60px;
  /* margin-top: 5px; */
  /* padding: 5px 2px; */
  border-radius: 0 0 5px 5px; 
  background-color: rgba(255, 255, 255, 0.6);
}
.home-info-desc__item {
  position: absolute;
  top: 5px;
  left: 15px;
  opacity: 0;
}
.home-info-desc__item.active {
  opacity: 1;
}
.desc-item__title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
}

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .home__info-holder {
    padding: 0;
    width: 320px;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 850px) {
}
