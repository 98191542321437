/* ----- TRANSITIONS ----- */
.Projects,
.Projects * {
  transition: var(--theme_transition-1);
}

/* ----- MAIN ----- */

/* .Projects {
} */

.projects__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}

.projects__section-head {
  margin: 25px 0 15px 0;
}
.projects-section__title {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 10px 0;
}
/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .projects__items {
    grid-template-columns: 1fr;
    /* grid-template-columns: 100%; */
  }
}

@media (min-width: 500px) {
  .projects__items {
    /* grid-template-columns: 1fr 1fr; */
    /* grid-template-columns: 50% 50%; */
    grid-auto-flow: dense;
  }
}

@media (min-width: 850px) {
}
