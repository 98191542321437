.loadingImg {
  /* background-color: rgba(200, 200, 200, 0.1); */
  animation: loader 1s ease-in-out 0s infinite alternate;
}

.PopUp-img {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.PopUp-img img {
  flex: 1 0 auto;
  height: 100%;
  width: 100% !important;
  margin: 0px !important;
  background: rgba(255, 255, 255, 0.985);
  border-radius: 10px;
  object-fit: contain;
}

.PopUp-img .option-btn {
  position: absolute;
  top: 30px;
  right: 40px;
  font-size: 1.4rem;
}

@keyframes loader {
  from {
    background-color: rgba(200, 200, 200, 0);
  }
  to {
    background-color: rgba(220, 220, 220, 1);
  }
}
