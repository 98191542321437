/* .Contact * {
  border: 1px dashed orange;
} */

.Contact {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 130px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  /* height: 100%; */
}

.contact__info {
  width: 300px;
  margin-right: 20px;
}

.contact-info__item {
  margin: 20px 0;
}

.contact-info__item span {
  color: var(--theme_font-color-2);
}
.contact-info__item > dt {
  font-weight: 600;
  font-size: 1.3rem;
  padding: 5px 0;
  color: var(--theme_font-color-2);
}
.contact-info-item__item {
  display: flex;
}

.contact-info-item__item > dt,
.contact-info-item__item > dd {
  font-weight: 500;
  font-size: 1.05rem;
  padding: 5px 0;
}

.contact-info-item__item > dt {
  font-weight: 600;
  width: 100px;
}

.contact__pic {
  height: 100%;
  width: 100%;
  max-width: 500px;
  /* background: salmon; */
}
.contact__pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ----- MEDIAS ----- */

@media (max-width: 650px) {
  .Contact {
    position: relative;
    justify-content: flex-end;
  }
  .contact__pic {
    position: absolute;
    top: 0;
    left: 0;
    max-width: unset;
    z-index: -10;
  }
  .contact__info {
    padding-left: 10px;
  }
  .contact-info__item:first-child {
    margin: 0px 0;
  }
  .contact__pic img {
    object-fit: cover;
  }
  .contact-info__item span,
  .contact-info__item > dt {
    color: white;
  }
  .contact-info-item__item > dt {
    width: 80px;
  }
}

@media (min-width: 550px) and (max-width: 760px) {
}
@media (min-width: 550px) {
}
@media (min-width: 750px) {
}
