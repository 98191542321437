/* body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
*/

@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Open+Sans:300,400,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Baloo+Paaji+2:400,500,600,700,800&display=swap");

:root {
  /* --home_color-1: #1d5f46; */
  /* --home_color-2: #639c87; */

  /* --theme_color-1: #1d5f46; */
  /* --theme_color-2: #639c87; */

  /* --theme_font-color-1: rgb(50, 50, 50);
  --theme_font-color-2: rgb(150, 150, 150); */

  --theme_border-color-1: rgba(120, 120, 120, 0.8);
  --theme_border-color-2: rgba(120, 120, 120, 0.5);
  --theme_border-color-3: rgba(120, 120, 120, 0.3);
  --theme_border-color-4: rgba(120, 120, 120, 0.1);

  --theme_shadowcolor-1: rgba(83, 97, 95, 0.5);
  --theme_shadowcolor-2: rgba(10, 100, 150, 0.2);

  --theme_transition-general: all 0.4s ease-in-out 0s;
  --theme_transition-1: all 0.3s ease-in-out 0.1s;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Baloo Paaji 2", "Lato", "Helvetica Neue", sans-serif;
  line-height: 20px;
  /* font-weight: 200; */
  /* border: 1px dashed orange; */
}

html {
  height: 100%;
}

body {
  height: 100%;
  /* overflow: scroll; */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  font-size: 0.85rem;
  /* overflow-y: scroll; */
  /* background: rgb(230, 230, 230); */
}

a {
  font-weight: 500;
  color: var(--theme_color-1);
}

.Mas-layout {
  max-width: 1400px;
  margin: auto;
  padding: 0 10px;
}
.Mas-layout2 {
  max-width: 900px;
  margin: auto;
  padding: 0 10px;
}

.option-btn {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 5px 0;
  color: var(--theme_color-2);
  text-decoration: underline;
  cursor: pointer;
}
.option-btn:hover {
  color: var(--theme_color-1);
  text-decoration: none;
}

.nonScroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.filter-invert {
  filter: invert();
}

@media (max-width: 500px) {
  .nonScroll-mobile {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
}

@media (min-width: 500px) {
  #root {
    font-size: 0.95rem;
  }
  .Mas-layout,
  .Mas-layout2 {
    padding: 0 20px;
  }
}

@media (min-width: 700px) {
  #root {
    font-size: 1rem;
  }
}
