.HEADER {
  /* border-bottom: 1px solid lightgray; */
  /* box-shadow: 0px 1px 20px rgba(153, 230, 233, 0.1); */
  /* background: rgba(255,255,255,.75) */
}
.header-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.header__logo {
  display: flex;
  align-items: flex-end;
}

.header-logo__sign {
  font-size: 3rem; /* this help aligining the item */

}

.header-logo__sign img {
  height: 50px;
  object-fit: contain;
  filter: var(--home-color-filter);
}

.header-logo__text {
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 16px;
  /* color: var(--theme_font-color-1); */
  color: var(--home_color-1);
  /* color: inherit; */
}
.header-logo__text > span {
  line-height: 16px;
  color: var(--theme_font-color-2);
}

.header__nav {
  display: flex;
  padding-top: 1px; /* adjustment */
}

.header-nav__item {
  padding: 1px 10px;
  height: 20px;
}

.header-nav__item:hover a{
  padding-right: 5px;
  font-weight: 500;
  color: var(--home_color-2);
  /* color: var(--theme_font-color-2); */
  border: 1px solid var(--home_color-2);
  border-width:0 2px 0 0;
}

.header-nav__item a {
  display: inline-block;
  height: 100%;
  /* padding: 5px 10px; */
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--home_color-1);
  /* vertical-align: middle; */
  /* filter: drop-shadow(0px 0px 1px white); */
}
.header-nav__item a.nav-item-active {
  padding-right: 5px;
  font-weight: 600;
  color: var(--home_color-2);
}

.header-holder__menu-grip {
  display: none;
}
.header-holder__menu-grip > div {
  height: 3px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(50, 50, 50);
  transition: all 0.2s ease-in-out 0s;
}
.menu-open .header-holder__menu-grip > div:first-child {
  transform: rotate(-45deg);
  transform: translateY(10px) rotate(-45deg);
  /* transform: translateY(10px); */
  transform-origin: center;
}
.menu-open .header-holder__menu-grip > div:nth-child(2) {
  opacity: 0;
}
.menu-open .header-holder__menu-grip > div:last-child {
  transform: translateY(-10px) rotate(45deg);
  transform-origin: center;
}

/* ----- TRANSITIONS ----- */
.HEADER * {
  transition: var(--theme_transition-1);
}

/* ----- MEDIAS ----- */

@media (max-width: 550px) {
  .header-holder {
    justify-content: flex-start;
  }
  .header-holder__menu-grip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 23px;
    width: 30px;
    margin-right: 10px;
  }
  /* .header-logo__text {
    color: var(--theme_color-1);
  } */
  /* .header-logo__text > span {
    color: var(--theme_font-color-2);
  } */
  .header__nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* justify-content: center; */
    top: 71px;
    left: 0;
    height: calc(100vh - 71px);
    width: 100vw;
    padding-top: 30px;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0;
    z-index: -10;
  }
  .menu-open .header__nav {
    opacity: 1;
    z-index: 10;
  }
  .header-nav__item {
    margin-right: 10px;
    padding: 15px 15px;
    padding: 20px;
    font-size: 20px;
    text-align: right;
    filter: none;
  }
  .header-nav__item a {
    font-size: 2rem;
    color: var(--theme_color-1);
  }
}

@media (min-width: 550px) and (max-width: 760px) {
  .header-nav__item a {
    font-size: 1.3rem;
  }

  .header__logo {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-logo__sign {
    height: 100%;
  }
  .header-logo__sign img {
    height: 40px;
  }

  .header-logo__text {
    margin-left: 0px;
    font-size: 0.95rem;
    line-height: 14px;
  }
  .header-logo__text > span {
    line-height: 14px;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 750px) {
  .header-nav__item a {
    font-size: 1.5rem;
  }
  .header-holder {
    flex-direction: row;
  }
}
