/* .Expertise{

} */

.expertise__explanation,
.expertise__fields {
  display: grid;
  grid-template-columns: 1fr;
}
.expertise-exp_item {
  margin: 15px 5px;
}
.expertise-exp_item .title {
  height: 40px;
  font-size: 1.1rem;
  font-weight: 600;
}
/* .expertise-exp_item .descirption{

} */

.expertise-fields_item {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid var(--theme_border-color1);
  background-color: lightgrey;
}
.expertise-fields_item .title {
  height: 50px;
  padding: 5px;
  font-size: 1.3rem;
  font-weight: 600;
  /* text-align: right; */
}
.expertise-fields_item .descirption {
  overflow: hidden;
  padding: 0 5px;
  font-size: 0.85rem;
  /* font-weight: 600; */
  text-align: justify;
}
.expertise-fields_item .option-btn {
  text-align: right;
}

/* .expertise-fields_item .descirption > p {
  height: 20px;
}
.expertise-fields_item .descirption.desc-expanded > p {
  height: 100%;
} */

/* ----- MEDIAS ----- */

@media (min-width: 500px) {
  .expertise__explanation,
  .expertise__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
