.projects__project {
  position: relative;
  overflow: hidden;
  min-height: 250px;
  margin: 1px;
  padding: 5px 10px;
  /* border: 1px solid var(--theme_border-color-2); */
  /* background-color: rgba(72, 133, 112, 0.5); */
}

.projects__project.selected {
  border: 1px solid var(--theme_border-color-2);
  border-width: 1px 0px;
  margin: 2px 1px;
  /* box-shadow: 0 0 5px var(--theme_shadowcolor-1); */
  /* background-color: rgba(143, 202, 183, 0.2); */
}

.project__background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* transition: filter .8s ease-in-out 0s; */
  /* filter: blur(1px) grayscale(80%) opacity(0.8); */
  filter: grayscale(80%) opacity(0.8);
  z-index: -10;
}
.project__background > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.projects__project:hover .project__background {
  /* transition: all 1s ease-in-out 0s; */
  filter: blur(0) grayscale(0) opacity(1);
}
.projects__project.selected > .project__background {
  opacity: 0;
}

.project__head {
  height: 40px;
  padding: 5px 0;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgba(225, 250, 241, 0.9);
  filter: drop-shadow(1px 1px 1px rgba(52, 61, 58, 0.9));
}
.projects__project:hover .project__head {
  letter-spacing: 1.4px;
  /* color: rgba(77, 156, 127, 0.9); */
  /* filter: drop-shadow(1px 1px 1px rgba(218, 240, 233, 0.9)); */
}

.project__body {
  display: flex;
  /* height: calc(100% - 40px); */
  /* height: 60vh; */
  height: 0;
  flex-direction: column;
  transition: all 0.25s ease-in 0s;
}
.selected .project__body {
  position: relative;
  height: 90vh;
  transition: all 1s ease-out 0.15s, width 0.1s ease-in-out 0s;
}

.project-body__slideshow {
  flex: 1 0 auto;
}

/* .project-body__info {
  } */

.project-info__title {
  font-size: 1.1rem;
  font-weight: 600;
  filter: drop-shadow(0px 0px 1px rgba(225, 250, 241, 0.9));
}
.selected .project-info__title {
  font-size: 1.2rem;
  font-weight: 600;
  filter: none;
}

.project-info__desc {
  position: relative;
  padding: 5px 0;
}
.selected .project-info__desc {
  display: block;
}

.desc__about-project {
  display: none;
}

.desc__text {
  overflow-y: hidden;
  font-size: 0.9rem;
  text-align: justify;
}

.project-info__desc .option-btn {
  text-align: right;
}

.project__expanded-team,
.project__expanded-desc {
  /* position: fixed; */
  display: none;
  position: absolute;
  overflow-y: scroll;
  top: 20px;
  /* left: 20px; */
  height: 100%;
  height: calc(100% - 50px);
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  /* width: calc(100% - 40px); */
  background: rgba(255, 255, 255, 0.985);
  box-shadow: 0 0 5px var(--theme_shadowcolor-1);
  border-radius: 5px;
  opacity: 0;
}
.project__expanded-team.team-expanded,
.project__expanded-desc.desc-expanded {
  display: block;
  opacity: 1;
  z-index: 100;
}
.project__expanded-team .option-btn,
.project__expanded-desc .option-btn {
  text-align: right;
}

.project-info__items {
  opacity: 0;
}
.selected .project-info__items {
  opacity: 1;
}

.project-info__item {
  font-size: 0.9rem;
  display: flex;
  flex-wrap: wrap;
}

.project-info__item > dt {
  /* font-size: .9rem; */
  font-weight: 600;
}
.project-info__item > dt::after {
  margin: 0 5px;
  content: ":";
  font-weight: 600;
}
/* .project-info__team {
  } */
.project-info__team .team__show-team {
  text-align: right;
}

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .project__background {
    filter: grayscale(0) opacity(1);
  }

  .option-btn.desc__about-project {
    display: block;
    text-align: right;
    margin: 0;
  }

  .project-info__desc .option-btn:not(.desc__about-project),
  .desc__text {
    display: none;
  }

  .project__expanded-team.team-expanded,
  .project__expanded-desc.desc-expanded {
    position: fixed;
    top: 10px;
    left: 10px;
    height: 100%;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  .project__expanded-team .option-btn,
  .project__expanded-desc .option-btn {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .project-team__items {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 850px) {
  .project__background {
    filter: grayscale(0) opacity(1);
  }
}

@media (min-width: 500px) {
  .projects__project.selected {
    grid-column: 1 / 3;
  }
  .project-info__item {
    font-size: 0.95rem;
  }
  .project-info__desc {
    font-size: 0.95rem;
  }
  .project-info__desc .option-btn {
    padding-top: 25px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.7) 20%,
      rgba(255, 255, 255, 1) 50%
    );
    transform: translateY(-20px);
  }
  .desc__text {
    max-height: 80px;
  }
}

@media (min-width: 850px) {
  .project-info__item {
    font-size: 0.95rem;
  }
  .selected .project__body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    height: 70vh;
  }
  .selected .project-body__slideshow {
    width: 65%;
  }
  .selected .project-body__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 300px;
    padding: 0 15px 50px 0px;
    /* border: 1px dashed salmon; */
  }

  .desc__text {
    max-height: 300px;
  }
}
