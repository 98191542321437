@import url("https://fonts.googleapis.com/css?family=Exo+2|Titillium+Web&display=swap");

.Footer-SIGN * {
  /* z-index: 1000; */
}

.Footer-SIGN {
  display: flex;
  overflow: hidden;
  height: 60px;
  justify-content: center;
}
@media (max-width: 500px) {
  /* because Mas-layout gives that padding */
  .Footer-SIGN {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.footer-sign_items {
  flex: 0 0 auto;
  height: 200px;
  padding: 5px 100px;
  border-radius: 200px;
  color: hsl(0, 0%, 75%);
  font-size: 0.9rem;
  font-weight: normal;
  text-align: center;
  transform: translateY(30px);
  transition: all 1s ease-out 0.5s;
  
}

.footer-sign_items:hover {
  color: hsl(0, 0%, 60%);
  background-color: hsl(0, 0%, 99%);
  transform: translateY(5px);
  box-shadow: 0 0 15px rgba(10, 100, 150, 0.15);
  transition-delay: 0s;
  z-index: 100;
}

.sign-clicked {
  color: black;
  box-shadow: 0 0 15px rgba(136, 211, 216, 0.4);
  margin-bottom: -140px;
  font-size: 1rem;
  max-width: 100vw;
  border-radius: 0;
}

.sign_item {
  font-family: "Titillium Web", sans-serif;
  flex: 1 0 auto;
}

.sign_website {
  text-align: center;
  transition: all 1s ease-in 0s;
}

.sign_website a,
.sign_website a:visited {
  letter-spacing: 2px;
  text-decoration: none;
  color: #7bbfcc;
}

.sign_website a:hover,
.sign_website a:active {
  filter: brightness(1.1);
}
