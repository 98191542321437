.Slideshow,
.Slideshow * {
  transition: var(--theme_transition-1);
}

.Slideshow {
  padding: 5px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-holder {
  flex: 1 0 auto;
  height: 200px;
  min-height: 200px;
  margin-bottom: 5px;
  /* border: 1px dashed var(--theme_border-color-1); */
  /* background-color: tan; */
  /* transition: var(--theme_transition-1), width .5s ease-in-out 2.5s; */
}
/* .selected .slide-holder {
  opacity: 1;
} */
.slide-holder img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* transition: none; */
}
.slide-control {
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid var(--theme_border-color-2); */
  /* background-color: rgb(212, 151, 71); */
}
.slide-control__previous,
.slide-control__next,
.slide-tumbnail {
  width: 30px;
  height: 30px;
  margin: 1px;
  border: 1px solid var(--theme_border-color-1);
  border-width: 1px 0;
}
.slide-control__previous,
.slide-control__next{
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  line-height: 27px;
  background: white;
  border-width: 1px 0;
}

.slide-control__previous:focus,
.slide-control__next:focus,
.slide-control__previous:active,
.slide-control__next:active{
  outline-color: white;
}
.slide-control__previous:disabled,
.slide-control__next:disabled{
  color: rgba(100,100,100,.4);
  border-color: rgba(100,100,100,.3);
}

.slide-tumbnail {
  filter: grayscale(100%) opacity(0.8);
}
.slide-tumbnail.slide-current {
  width: 50px;
  background: var(--theme_color-1);
  border: 2px solid var(--theme_color-1);
  filter: none;
}

.slide-control__tumbnails {
  /* flex: 1 0 auto; */
  margin: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-content: flex-start; */
}

.slide-tumbnail {
  /* flex: 0 0 auto; */
  /* width: 50px; */
  border: 1px solid var(--theme_border-color-1);
}
.slide-tumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ----- MEDIAS ----- */

@media (min-width: 500px) {
  .slide-control__previous,
  .slide-control__next,
  .slide-tumbnail {
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
    line-height: 36px;
  }
}
