@import url(https://fonts.googleapis.com/css?family=Exo+2|Titillium+Web&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Baloo+Paaji+2:400,500,600,700,800&display=swap);
.HEADER {
  /* border-bottom: 1px solid lightgray; */
  /* box-shadow: 0px 1px 20px rgba(153, 230, 233, 0.1); */
  /* background: rgba(255,255,255,.75) */
}
.header-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.header__logo {
  display: flex;
  align-items: flex-end;
}

.header-logo__sign {
  font-size: 3rem; /* this help aligining the item */

}

.header-logo__sign img {
  height: 50px;
  object-fit: contain;
  -webkit-filter: var(--home-color-filter);
          filter: var(--home-color-filter);
}

.header-logo__text {
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 16px;
  /* color: var(--theme_font-color-1); */
  color: var(--home_color-1);
  /* color: inherit; */
}
.header-logo__text > span {
  line-height: 16px;
  color: var(--theme_font-color-2);
}

.header__nav {
  display: flex;
  padding-top: 1px; /* adjustment */
}

.header-nav__item {
  padding: 1px 10px;
  height: 20px;
}

.header-nav__item:hover a{
  padding-right: 5px;
  font-weight: 500;
  color: var(--home_color-2);
  /* color: var(--theme_font-color-2); */
  border: 1px solid var(--home_color-2);
  border-width:0 2px 0 0;
}

.header-nav__item a {
  display: inline-block;
  height: 100%;
  /* padding: 5px 10px; */
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--home_color-1);
  /* vertical-align: middle; */
  /* filter: drop-shadow(0px 0px 1px white); */
}
.header-nav__item a.nav-item-active {
  padding-right: 5px;
  font-weight: 600;
  color: var(--home_color-2);
}

.header-holder__menu-grip {
  display: none;
}
.header-holder__menu-grip > div {
  height: 3px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(50, 50, 50);
  transition: all 0.2s ease-in-out 0s;
}
.menu-open .header-holder__menu-grip > div:first-child {
  transform: rotate(-45deg);
  transform: translateY(10px) rotate(-45deg);
  /* transform: translateY(10px); */
  transform-origin: center;
}
.menu-open .header-holder__menu-grip > div:nth-child(2) {
  opacity: 0;
}
.menu-open .header-holder__menu-grip > div:last-child {
  transform: translateY(-10px) rotate(45deg);
  transform-origin: center;
}

/* ----- TRANSITIONS ----- */
.HEADER * {
  transition: var(--theme_transition-1);
}

/* ----- MEDIAS ----- */

@media (max-width: 550px) {
  .header-holder {
    justify-content: flex-start;
  }
  .header-holder__menu-grip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 23px;
    width: 30px;
    margin-right: 10px;
  }
  /* .header-logo__text {
    color: var(--theme_color-1);
  } */
  /* .header-logo__text > span {
    color: var(--theme_font-color-2);
  } */
  .header__nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* justify-content: center; */
    top: 71px;
    left: 0;
    height: calc(100vh - 71px);
    width: 100vw;
    padding-top: 30px;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0;
    z-index: -10;
  }
  .menu-open .header__nav {
    opacity: 1;
    z-index: 10;
  }
  .header-nav__item {
    margin-right: 10px;
    padding: 15px 15px;
    padding: 20px;
    font-size: 20px;
    text-align: right;
    -webkit-filter: none;
            filter: none;
  }
  .header-nav__item a {
    font-size: 2rem;
    color: var(--theme_color-1);
  }
}

@media (min-width: 550px) and (max-width: 760px) {
  .header-nav__item a {
    font-size: 1.3rem;
  }

  .header__logo {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-logo__sign {
    height: 100%;
  }
  .header-logo__sign img {
    height: 40px;
  }

  .header-logo__text {
    margin-left: 0px;
    font-size: 0.95rem;
    line-height: 14px;
  }
  .header-logo__text > span {
    line-height: 14px;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 750px) {
  .header-nav__item a {
    font-size: 1.5rem;
  }
  .header-holder {
    flex-direction: row;
  }
}

.loadingImg {
  /* background-color: rgba(200, 200, 200, 0.1); */
  -webkit-animation: loader 1s ease-in-out 0s infinite alternate;
          animation: loader 1s ease-in-out 0s infinite alternate;
}

.PopUp-img {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.PopUp-img img {
  flex: 1 0 auto;
  height: 100%;
  width: 100% !important;
  margin: 0px !important;
  background: rgba(255, 255, 255, 0.985);
  border-radius: 10px;
  object-fit: contain;
}

.PopUp-img .option-btn {
  position: absolute;
  top: 30px;
  right: 40px;
  font-size: 1.4rem;
}

@-webkit-keyframes loader {
  from {
    background-color: rgba(200, 200, 200, 0);
  }
  to {
    background-color: rgba(220, 220, 220, 1);
  }
}

@keyframes loader {
  from {
    background-color: rgba(200, 200, 200, 0);
  }
  to {
    background-color: rgba(220, 220, 220, 1);
  }
}

.Home * {
  transition: all 0.5s ease-in-out 0s;
}

/* .Home { */
/* width: 100vw; */
/* height: calc(100vh - 138px); */
/* } */

.home__background-holder {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}
.home__background-holder {
  /* height: calc(100vh - 130px); */
  height: 100%;
  /* top: 70px; */
  top: 0px;
}

.home-background__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.home-background__item.active {
  opacity: 1;
}
.home-background__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home__info {
  position: fixed;
  bottom: 100px;
  width: 100vw;
}

.home__info-holder {
  /* min-width: 300px; */
  /* width: 50%; */
  /* max-width: 300px; */
  /* background-color: rgba(255, 255, 255, 0.6); */
  text-align: center;
}


.home-info__control {
  display: flex;
  display: inline-flex;/* STYLE UPDATE:------- if wanted to be center ----- */
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  /* width: 300px; */
  height: 20px;
  padding: 5px 2px;
  padding: 5px ; /* STYLE UPDATE:-------  */
  /* border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px; */
  border-radius: 5px 5px 0 0; 
  border-radius: 10px ; /* STYLE UPDATE:-------  */
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  /* background-color: rgba(255, 255, 255, 0.5); */
}
.home-info-control__item {
  height: 11px;
  width: 11px;
  margin: 0 10px;
  border: 1px solid var(--theme_color-1);
  border-radius: 50%;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.4);/* STYLE UPDATE:-------  */
}
.home-info-control__item.active {
  border: 1px solid white;
  border: 1px solid rgba(255, 255, 255, 0.4);/* STYLE UPDATE:-------  */
  background-color: var(--theme_color-1);
}

.home-info__desc {
  position: relative;
  width: 300px;
  height: 60px;
  /* margin-top: 5px; */
  /* padding: 5px 2px; */
  border-radius: 0 0 5px 5px; 
  background-color: rgba(255, 255, 255, 0.6);
}
.home-info-desc__item {
  position: absolute;
  top: 5px;
  left: 15px;
  opacity: 0;
}
.home-info-desc__item.active {
  opacity: 1;
}
.desc-item__title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
}

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .home__info-holder {
    padding: 0;
    width: 320px;
  }
}

@media (min-width: 500px) {
}

@media (min-width: 850px) {
}

.Slideshow,
.Slideshow * {
  transition: var(--theme_transition-1);
}

.Slideshow {
  padding: 5px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-holder {
  flex: 1 0 auto;
  height: 200px;
  min-height: 200px;
  margin-bottom: 5px;
  /* border: 1px dashed var(--theme_border-color-1); */
  /* background-color: tan; */
  /* transition: var(--theme_transition-1), width .5s ease-in-out 2.5s; */
}
/* .selected .slide-holder {
  opacity: 1;
} */
.slide-holder img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* transition: none; */
}
.slide-control {
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid var(--theme_border-color-2); */
  /* background-color: rgb(212, 151, 71); */
}
.slide-control__previous,
.slide-control__next,
.slide-tumbnail {
  width: 30px;
  height: 30px;
  margin: 1px;
  border: 1px solid var(--theme_border-color-1);
  border-width: 1px 0;
}
.slide-control__previous,
.slide-control__next{
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  line-height: 27px;
  background: white;
  border-width: 1px 0;
}

.slide-control__previous:focus,
.slide-control__next:focus,
.slide-control__previous:active,
.slide-control__next:active{
  outline-color: white;
}
.slide-control__previous:disabled,
.slide-control__next:disabled{
  color: rgba(100,100,100,.4);
  border-color: rgba(100,100,100,.3);
}

.slide-tumbnail {
  -webkit-filter: grayscale(100%) opacity(0.8);
          filter: grayscale(100%) opacity(0.8);
}
.slide-tumbnail.slide-current {
  width: 50px;
  background: var(--theme_color-1);
  border: 2px solid var(--theme_color-1);
  -webkit-filter: none;
          filter: none;
}

.slide-control__tumbnails {
  /* flex: 1 0 auto; */
  margin: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-content: flex-start; */
}

.slide-tumbnail {
  /* flex: 0 0 auto; */
  /* width: 50px; */
  border: 1px solid var(--theme_border-color-1);
}
.slide-tumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ----- MEDIAS ----- */

@media (min-width: 500px) {
  .slide-control__previous,
  .slide-control__next,
  .slide-tumbnail {
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
    line-height: 36px;
  }
}

.projects__project {
  position: relative;
  overflow: hidden;
  min-height: 250px;
  margin: 1px;
  padding: 5px 10px;
  /* border: 1px solid var(--theme_border-color-2); */
  /* background-color: rgba(72, 133, 112, 0.5); */
}

.projects__project.selected {
  border: 1px solid var(--theme_border-color-2);
  border-width: 1px 0px;
  margin: 2px 1px;
  /* box-shadow: 0 0 5px var(--theme_shadowcolor-1); */
  /* background-color: rgba(143, 202, 183, 0.2); */
}

.project__background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* transition: filter .8s ease-in-out 0s; */
  /* filter: blur(1px) grayscale(80%) opacity(0.8); */
  -webkit-filter: grayscale(80%) opacity(0.8);
          filter: grayscale(80%) opacity(0.8);
  z-index: -10;
}
.project__background > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.projects__project:hover .project__background {
  /* transition: all 1s ease-in-out 0s; */
  -webkit-filter: blur(0) grayscale(0) opacity(1);
          filter: blur(0) grayscale(0) opacity(1);
}
.projects__project.selected > .project__background {
  opacity: 0;
}

.project__head {
  height: 40px;
  padding: 5px 0;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgba(225, 250, 241, 0.9);
  -webkit-filter: drop-shadow(1px 1px 1px rgba(52, 61, 58, 0.9));
          filter: drop-shadow(1px 1px 1px rgba(52, 61, 58, 0.9));
}
.projects__project:hover .project__head {
  letter-spacing: 1.4px;
  /* color: rgba(77, 156, 127, 0.9); */
  /* filter: drop-shadow(1px 1px 1px rgba(218, 240, 233, 0.9)); */
}

.project__body {
  display: flex;
  /* height: calc(100% - 40px); */
  /* height: 60vh; */
  height: 0;
  flex-direction: column;
  transition: all 0.25s ease-in 0s;
}
.selected .project__body {
  position: relative;
  height: 90vh;
  transition: all 1s ease-out 0.15s, width 0.1s ease-in-out 0s;
}

.project-body__slideshow {
  flex: 1 0 auto;
}

/* .project-body__info {
  } */

.project-info__title {
  font-size: 1.1rem;
  font-weight: 600;
  -webkit-filter: drop-shadow(0px 0px 1px rgba(225, 250, 241, 0.9));
          filter: drop-shadow(0px 0px 1px rgba(225, 250, 241, 0.9));
}
.selected .project-info__title {
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-filter: none;
          filter: none;
}

.project-info__desc {
  position: relative;
  padding: 5px 0;
}
.selected .project-info__desc {
  display: block;
}

.desc__about-project {
  display: none;
}

.desc__text {
  overflow-y: hidden;
  font-size: 0.9rem;
  text-align: justify;
}

.project-info__desc .option-btn {
  text-align: right;
}

.project__expanded-team,
.project__expanded-desc {
  /* position: fixed; */
  display: none;
  position: absolute;
  overflow-y: scroll;
  top: 20px;
  /* left: 20px; */
  height: 100%;
  height: calc(100% - 50px);
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  /* width: calc(100% - 40px); */
  background: rgba(255, 255, 255, 0.985);
  box-shadow: 0 0 5px var(--theme_shadowcolor-1);
  border-radius: 5px;
  opacity: 0;
}
.project__expanded-team.team-expanded,
.project__expanded-desc.desc-expanded {
  display: block;
  opacity: 1;
  z-index: 100;
}
.project__expanded-team .option-btn,
.project__expanded-desc .option-btn {
  text-align: right;
}

.project-info__items {
  opacity: 0;
}
.selected .project-info__items {
  opacity: 1;
}

.project-info__item {
  font-size: 0.9rem;
  display: flex;
  flex-wrap: wrap;
}

.project-info__item > dt {
  /* font-size: .9rem; */
  font-weight: 600;
}
.project-info__item > dt::after {
  margin: 0 5px;
  content: ":";
  font-weight: 600;
}
/* .project-info__team {
  } */
.project-info__team .team__show-team {
  text-align: right;
}

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .project__background {
    -webkit-filter: grayscale(0) opacity(1);
            filter: grayscale(0) opacity(1);
  }

  .option-btn.desc__about-project {
    display: block;
    text-align: right;
    margin: 0;
  }

  .project-info__desc .option-btn:not(.desc__about-project),
  .desc__text {
    display: none;
  }

  .project__expanded-team.team-expanded,
  .project__expanded-desc.desc-expanded {
    position: fixed;
    top: 10px;
    left: 10px;
    height: 100%;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
  }

  .project__expanded-team .option-btn,
  .project__expanded-desc .option-btn {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .project-team__items {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 850px) {
  .project__background {
    -webkit-filter: grayscale(0) opacity(1);
            filter: grayscale(0) opacity(1);
  }
}

@media (min-width: 500px) {
  .projects__project.selected {
    grid-column: 1 / 3;
  }
  .project-info__item {
    font-size: 0.95rem;
  }
  .project-info__desc {
    font-size: 0.95rem;
  }
  .project-info__desc .option-btn {
    padding-top: 25px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.7) 20%,
      rgba(255, 255, 255, 1) 50%
    );
    transform: translateY(-20px);
  }
  .desc__text {
    max-height: 80px;
  }
}

@media (min-width: 850px) {
  .project-info__item {
    font-size: 0.95rem;
  }
  .selected .project__body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    height: 70vh;
  }
  .selected .project-body__slideshow {
    width: 65%;
  }
  .selected .project-body__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 300px;
    padding: 0 15px 50px 0px;
    /* border: 1px dashed salmon; */
  }

  .desc__text {
    max-height: 300px;
  }
}

/* ----- TRANSITIONS ----- */
.Projects,
.Projects * {
  transition: var(--theme_transition-1);
}

/* ----- MAIN ----- */

/* .Projects {
} */

.projects__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}

.projects__section-head {
  margin: 25px 0 15px 0;
}
.projects-section__title {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 10px 0;
}
/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .projects__items {
    grid-template-columns: 1fr;
    /* grid-template-columns: 100%; */
  }
}

@media (min-width: 500px) {
  .projects__items {
    /* grid-template-columns: 1fr 1fr; */
    /* grid-template-columns: 50% 50%; */
    grid-auto-flow: dense;
  }
}

@media (min-width: 850px) {
}

/* .Expertise{

} */

.expertise__explanation,
.expertise__fields {
  display: grid;
  grid-template-columns: 1fr;
}
.expertise-exp_item {
  margin: 15px 5px;
}
.expertise-exp_item .title {
  height: 40px;
  font-size: 1.1rem;
  font-weight: 600;
}
/* .expertise-exp_item .descirption{

} */

.expertise-fields_item {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid var(--theme_border-color1);
  background-color: lightgrey;
}
.expertise-fields_item .title {
  height: 50px;
  padding: 5px;
  font-size: 1.3rem;
  font-weight: 600;
  /* text-align: right; */
}
.expertise-fields_item .descirption {
  overflow: hidden;
  padding: 0 5px;
  font-size: 0.85rem;
  /* font-weight: 600; */
  text-align: justify;
}
.expertise-fields_item .option-btn {
  text-align: right;
}

/* .expertise-fields_item .descirption > p {
  height: 20px;
}
.expertise-fields_item .descirption.desc-expanded > p {
  height: 100%;
} */

/* ----- MEDIAS ----- */

@media (min-width: 500px) {
  .expertise__explanation,
  .expertise__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

/* .About * {
  border: 1px dashed rgba(184, 163, 124, 0.2);
} */
/* .About {
} */

.About > section {
  margin: 50px 0;
}

.about__brand,
.about__personal {
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.about__section__image {
  /* height: 100%; */
  width: 30%;
  max-width: 200px;
}
.about__section__image > img {
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  min-height: 20px;
}
.about__section__body {
  width: 65%;
  max-width: 400px;
  padding-left: 20px;
}

.about-section__disc p {
  font-size: 1rem;
  text-align: justify;
}

.about-section__title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.about-section__icons {
  display: flex;
  justify-content: space-evenly;
  /* justify-content: space-between; */
  justify-content: center;
  margin: 10px 0;}

.about-section__icons img {
  /* width: 100px; */
  /* width: 20%; */
  height: 100%;
  min-height: 20px;
  margin: 0 10px;
  object-fit: contain;
  box-shadow: 0px 0px 10px rgba(150, 150, 150, 0.1);
}

.about__personal .about-section__icons {
  height: 120px;
}
.about__personal .about-section__icons img {
  box-shadow: none;
}

.about__awards .about-section__icons {
  height: 130px;
}
.about__awards .about-section__icons img {
  width: 16%;
}

/* .about__publication .about-section__icons {
} */
.about__publication .about-section__icons img {
  width: 22.2%;
}

/* .about__brand .about-section__disc {
  margin-top: 50px;
} */

/* ----- MEDIAS ----- */

@media (max-width: 500px) {
  .about__brand,
  .about__personal {
    flex-direction: column;
  }

  .about__brand .about-section__disc {
    margin-top: 5px;
  }

  .about-section__disc p {
    font-size: 0.95rem;
    word-spacing: 0px;
    line-height: 17px;
  }
  .about__section__image {
    width: 180px;
    padding-left: 20px;
    margin-bottom: 5px;
  }
  .about__section__image > img {
    height: 100%;
    object-fit: contain;
  }
  .about__section__body {
    width: 100%;
    max-width: 500px;
    padding-right: 20px;
  }
  .about__personal .about-section__icons {
    height: 100px;
  }
  .about__awards .about-section__icons {
    height: 100px;
  }
  .about__awards .about-section__icons img {
    width: 20%;
  }
}

@media (min-width: 550px) and (max-width: 750px) {
  .about-section__disc p {
    font-size: 1rem;
    word-spacing: 0px;
    line-height: 18px;
  }
  .about-section__icons {
    height: 200px;
  }
  .about__personal .about-section__icons {
    height: 120px;
  }
  .about__awards .about-section__icons {
    height: 110px;
  }
}

@media (min-width: 750px) {
}

/* .Contact * {
  border: 1px dashed orange;
} */

.Contact {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 130px);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  /* height: 100%; */
}

.contact__info {
  width: 300px;
  margin-right: 20px;
}

.contact-info__item {
  margin: 20px 0;
}

.contact-info__item span {
  color: var(--theme_font-color-2);
}
.contact-info__item > dt {
  font-weight: 600;
  font-size: 1.3rem;
  padding: 5px 0;
  color: var(--theme_font-color-2);
}
.contact-info-item__item {
  display: flex;
}

.contact-info-item__item > dt,
.contact-info-item__item > dd {
  font-weight: 500;
  font-size: 1.05rem;
  padding: 5px 0;
}

.contact-info-item__item > dt {
  font-weight: 600;
  width: 100px;
}

.contact__pic {
  height: 100%;
  width: 100%;
  max-width: 500px;
  /* background: salmon; */
}
.contact__pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ----- MEDIAS ----- */

@media (max-width: 650px) {
  .Contact {
    position: relative;
    justify-content: flex-end;
  }
  .contact__pic {
    position: absolute;
    top: 0;
    left: 0;
    max-width: unset;
    z-index: -10;
  }
  .contact__info {
    padding-left: 10px;
  }
  .contact-info__item:first-child {
    margin: 0px 0;
  }
  .contact__pic img {
    object-fit: cover;
  }
  .contact-info__item span,
  .contact-info__item > dt {
    color: white;
  }
  .contact-info-item__item > dt {
    width: 80px;
  }
}

@media (min-width: 550px) and (max-width: 760px) {
}
@media (min-width: 550px) {
}
@media (min-width: 750px) {
}

/* .MAIN { */
  /* height: calc(100vh - 50px); */
  /* background: tan; */
/* } */

@media (max-width: 500px) {
  .MAIN.menu-open {
    display: none;
  }
}

.Footer-SIGN * {
  /* z-index: 1000; */
}

.Footer-SIGN {
  display: flex;
  overflow: hidden;
  height: 60px;
  justify-content: center;
}
@media (max-width: 500px) {
  /* because Mas-layout gives that padding */
  .Footer-SIGN {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.footer-sign_items {
  flex: 0 0 auto;
  height: 200px;
  padding: 5px 100px;
  border-radius: 200px;
  color: hsl(0, 0%, 75%);
  font-size: 0.9rem;
  font-weight: normal;
  text-align: center;
  transform: translateY(30px);
  transition: all 1s ease-out 0.5s;
  
}

.footer-sign_items:hover {
  color: hsl(0, 0%, 60%);
  background-color: hsl(0, 0%, 99%);
  transform: translateY(5px);
  box-shadow: 0 0 15px rgba(10, 100, 150, 0.15);
  transition-delay: 0s;
  z-index: 100;
}

.sign-clicked {
  color: black;
  box-shadow: 0 0 15px rgba(136, 211, 216, 0.4);
  margin-bottom: -140px;
  font-size: 1rem;
  max-width: 100vw;
  border-radius: 0;
}

.sign_item {
  font-family: "Titillium Web", sans-serif;
  flex: 1 0 auto;
}

.sign_website {
  text-align: center;
  transition: all 1s ease-in 0s;
}

.sign_website a,
.sign_website a:visited {
  letter-spacing: 2px;
  text-decoration: none;
  color: #7bbfcc;
}

.sign_website a:hover,
.sign_website a:active {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.cls-icons {
  /* stroke: rgb(50, 50, 50); */
  stroke: var(--home_color-2);
  stroke-width: 1px;
  fill: var(--home_color-1);
  /* opacity: 0.5; */
}


.FOOTER {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: auto; */
  /* min-height: 25px; */
  /* border-top: 1px solid lightgray; */
  /* min-height: 25px; */
  /* background: lightsalmon; */
  /* background: rgba(255,255,255,.75) */
}
.FOOTER * {
  /* border: 1px dashed rgb(240, 190, 97); */
}

.footer-holder {
  position: relative;
  height: 60px;
}

.footer__spacer {
  width: 120px;
}

.footer__icons {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}
.footer-icons__item {
  height: 20px;
  width: 40px;
  padding: 0 5px;
  opacity: 0.5;
  transition: all .2s ease-in 0s;
}
.footer-icons__item:hover {
  opacity: 0.8;
}

.footer-icons__item svg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}



@media (max-width: 500px) {
  .FOOTER.menu-open {
    display: none;
  }
  .footer__icons {
    top: 8px;
    left: 0;
    width: 100%;
    justify-content: center;
  }
  .footer-icons__item {
    /* height: 18px; */
  }
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
*/

:root {
  /* --home_color-1: #1d5f46; */
  /* --home_color-2: #639c87; */

  /* --theme_color-1: #1d5f46; */
  /* --theme_color-2: #639c87; */

  /* --theme_font-color-1: rgb(50, 50, 50);
  --theme_font-color-2: rgb(150, 150, 150); */

  --theme_border-color-1: rgba(120, 120, 120, 0.8);
  --theme_border-color-2: rgba(120, 120, 120, 0.5);
  --theme_border-color-3: rgba(120, 120, 120, 0.3);
  --theme_border-color-4: rgba(120, 120, 120, 0.1);

  --theme_shadowcolor-1: rgba(83, 97, 95, 0.5);
  --theme_shadowcolor-2: rgba(10, 100, 150, 0.2);

  --theme_transition-general: all 0.4s ease-in-out 0s;
  --theme_transition-1: all 0.3s ease-in-out 0.1s;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Baloo Paaji 2", "Lato", "Helvetica Neue", sans-serif;
  line-height: 20px;
  /* font-weight: 200; */
  /* border: 1px dashed orange; */
}

html {
  height: 100%;
}

body {
  height: 100%;
  /* overflow: scroll; */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  font-size: 0.85rem;
  /* overflow-y: scroll; */
  /* background: rgb(230, 230, 230); */
}

a {
  font-weight: 500;
  color: var(--theme_color-1);
}

.Mas-layout {
  max-width: 1400px;
  margin: auto;
  padding: 0 10px;
}
.Mas-layout2 {
  max-width: 900px;
  margin: auto;
  padding: 0 10px;
}

.option-btn {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 5px 0;
  color: var(--theme_color-2);
  text-decoration: underline;
  cursor: pointer;
}
.option-btn:hover {
  color: var(--theme_color-1);
  text-decoration: none;
}

.nonScroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.filter-invert {
  -webkit-filter: invert();
          filter: invert();
}

@media (max-width: 500px) {
  .nonScroll-mobile {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
}

@media (min-width: 500px) {
  #root {
    font-size: 0.95rem;
  }
  .Mas-layout,
  .Mas-layout2 {
    padding: 0 20px;
  }
}

@media (min-width: 700px) {
  #root {
    font-size: 1rem;
  }
}

