/* .MAIN { */
  /* height: calc(100vh - 50px); */
  /* background: tan; */
/* } */

@media (max-width: 500px) {
  .MAIN.menu-open {
    display: none;
  }
}
